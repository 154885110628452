import { Localized } from "../../packages/localization/Localized"
import { IsNode } from "../AssertNode"
import { CloneGraph } from "../Graph"
import { UndoImageToUrl } from "./File"
import { MatchesTypeJIT } from "./MatchesTypeJIT"
import { TypeValidator } from "./Primitives/TypeValidator"
import { Uuid } from "./Primitives/Uuid"
import { Visitor } from "./Visitor"
import { SubstituteAndDiscriminate } from "../ReflectionInfo"
import { Tags } from "./Tags"

/**
 * @shared
 */
export type Type =
    | UnionType
    | IntersectionType
    | ArrayType
    | StringType
    | NumberType
    | BooleanType
    | NullType
    | UndefinedType
    | AnyType
    | UnknownType
    | ObjectType
    | PromiseType
    | AsyncGeneratorType
    | FunctionType
    | DateType
    | VoidType
    | NeverType
    | ParameterType
    | FileType
    | SymbolType

export type OpaqueStringType = StringType & {
    /** A string that distinguishes this opaque string type from other opaque string types.
     * Typically the same as the type's alias. */
    opaque: string
}
export function OpaqueStringType(opaque: string): OpaqueStringType {
    return { string: null, opaque }
}

export type DateStringType = OpaqueStringType

export function IsOpaqueStringType(type: Type): type is OpaqueStringType {
    return typeof type === "object" && "string" in type && "opaque" in type
}
export function IsDateStringType(type: Type): type is DateStringType {
    return typeof type === "object" && type.alias === "DateString"
}

export function HasDocumentation(type: Type): type is Type & { documentation: string } {
    return typeof type === "object" && "documentation" in type
}

export function SetTypeAlias<T extends Type>(alias: string | undefined, type: T): Type {
    if (
        typeof type === "object" &&
        alias &&
        !type.alias &&
        !alias.startsWith("__") &&
        alias !== "Array"
    ) {
        type.alias = alias
    }
    return type
}
export function GetTypeAlias<T>(type: Type): string | undefined {
    if (typeof type === "object") {
        return type.alias
    }
}

export function UnionType(...union: Type[]): UnionType {
    return { union }
}
export function IntersectionType(...intersection: Type[]): IntersectionType {
    return { intersection }
}
export const StringType = "string" as const
export const NumberType = "number" as const
export const IntegerType = "number" as const
export const BooleanType = "boolean" as const
export const NullType = "null" as const
export const UndefinedType = "undefined" as const
export const VoidType = "void" as const
export const AnyType = "any" as const
export const UnknownType = "unknown" as const
export const NeverType = "never" as const
export const DateType = "date" as const
export const FunctionType = "function" as const

export function RangedIntegerType(
    alias: string,
    minValue?: number,
    maxValue?: number
): IntegerType {
    return { number: null, integer: true, alias, minValue, maxValue }
}

export function StringLiteral(value: string): StringLiteralType {
    return { string: value }
}
export function NumberLiteral(value: number): NumberLiteralType {
    return { number: value }
}
export function BooleanLiteral(value: boolean): BooleanLiteralType {
    return { boolean: value }
}
export function ArrayType(itemType: Type = "unknown"): ArrayType {
    return { array: itemType }
}
export const BufferType: BufferType = { buffer: null }
export function PromiseType(payloadType: Type): PromiseType {
    return { promise: payloadType }
}
export function ParameterType(name: string): ParameterType {
    return { param: name }
}

export type StringLiteralType = StringType & { string: string }
export type NumberLiteralType = NumberType & { number: number }
export type BooleanLiteralType = BooleanType & { boolean: boolean }

export type LiteralType =
    | StringLiteralType
    | NumberLiteralType
    | BooleanLiteralType
    | NullType
    | UndefinedType

export type FileType = TypeBase & { mimeType: string }
export function IsFileType(type: Type): type is FileType {
    return typeof type === "object" && "mimeType" in type
}
export function IsFileTypeCompatible(type: Type): boolean {
    if (typeof type === "object") {
        if ("mimeType" in type) return true
        if ("union" in type) {
            return type.union.every(IsFileTypeCompatible)
        }
    }
    return false
}

export function IsStringType(type: Type): type is StringType {
    return Is(type, "string")
}
export function IsNumberType(type: Type): type is NumberType {
    return Is(type, "number") || Is(type, "integer")
}
export function IsIntegerType(type: Type): type is IntegerType {
    return typeof type === "string" ? type === "integer" : "number" in type && !!type.integer
}
export function IsBooleanType(type: Type): type is BooleanType {
    return Is(type, "boolean")
}
export function IsLiteralType(type: Type): type is LiteralType {
    return (
        IsStringLiteral(type) ||
        IsNumberLiteral(type) ||
        IsBooleanLiteral(type) ||
        IsNullType(type) ||
        IsUndefinedType(type)
    )
}
export function IsStringLiteral(type: Type): type is StringLiteralType {
    return typeof type !== "string" && "string" in type && type.string !== null
}
export function IsNumberLiteral(type: Type): type is NumberLiteralType {
    return typeof type !== "string" && "number" in type && type.number !== null
}
export function IsBooleanLiteral(type: Type): type is BooleanLiteralType {
    return typeof type !== "string" && "boolean" in type && type.boolean !== null
}

function Is(a: Type, keyword: string) {
    return typeof a === "string" ? a === keyword : typeof a === "object" && keyword in a
}

export function IsNullType(type: Type): type is NullType {
    return Is(type, "null")
}
export function IsUndefinedType(type: Type): type is UndefinedType {
    return Is(type, "undefined")
}
export function IsAnyType(type: Type): type is AnyType {
    return Is(type, "any")
}
export function IsUnknownType(type: Type): type is UnknownType {
    return Is(type, "unknown")
}
export function IsUnionType(type: Type): type is UnionType {
    return Is(type, "union")
}
export function IsIntersectionType(type: Type): type is IntersectionType {
    return Is(type, "intersection")
}
export function IsArrayType(type: Type): type is ArrayType {
    return Is(type, "array")
}
export function IsObjectType(type: Type): type is ObjectType {
    return Is(type, "props")
}
export function IsClassType(
    type: Type,
    /** Specifies a class name that either must be equal to the alias of this
     * type, or one of its super types. */
    className?: string
): type is ObjectType & { isClass: true } {
    return (
        IsObjectType(type) &&
        "isClass" in type &&
        !!type.isClass &&
        (!className
            ? true
            : type.alias === className || (!!type.super && IsClassType(type.super, className)))
    )
}
export function IsVoidType(type: Type): type is VoidType {
    return typeof type !== "string" ? "void" in type : type === "void"
}
export function IsParameterType(type: Type): type is ParameterType {
    return Is(type, "param")
}
export type AtomicType = StringType | NumberType | BooleanType
export function IsAtomic(type: Type): type is AtomicType {
    return IsStringType(type) || IsNumberType(type) || IsBooleanType(type)
}
export function IsFunctionType(type: Type): type is FunctionType {
    return Is(type, "function")
}
export function IsDateType(type: Type): type is DateType {
    return Is(type, "date")
}
export function IsPromiseType(type: Type): type is PromiseType {
    return Is(type, "promise")
}
export function IsNeverType(type: Type): type is NeverType {
    return Is(type, "never")
}

export type TypeBase = {
    alias?: string
    sourceFile?: string

    /** If this type has been renamed (typically due to an endpoint transform),
     * this field holds the original alias. */
    originalAlias?: string
    typeArgs?: Type[]
    documentation?: string
    tags?: Tags
    /** If set, this type is incompatible with other types where this field doesn't match. */
    opaque?: string
    /** The source code of a javascript function that will validate this type.
     *
     *  * Should take a candidate instance of this type as argument
     *  * Should throw an Error if validation fails
     *  * Should return the valid instance of this type if validation succeeds
     *  * May also accept non-instances of this type and convert them to valid
     *    instances of this type
     */
    validator?: string

    /** If true, the server has a defaults function that can be used to enrich a new instance of
     * this type  more suitable default values. Use the `postDocumentObjectDefaults` endpoint to
     * invoke it.  */
    hasDefaults?: boolean

    /** If this type was declared as a lookup on a different, named type, this holds that
     * information. This indicates that this type represents a reference to a different entity.
     *
     * For example, if a field is declared like this:
     *
     * ```ts
     *    account: Account["id"]
     * ```
     *
     *  Then the type will be the same as the indicated field (`Account.id`), but in addition, the
     *  type will be decorated with `reference: { typeName: "Account", fieldName: "id"}`.
     */
    reference?: Reference
}

/** Information about what a type represents a reference to. */
export type Reference = {
    /** The name (alias) of the type that is being referenced */
    typeName: string
    /** The field of the type that is being referenced. */
    fieldName: string
}

/** If the provided type is a reference, this function returns the reference information.
 *
 *  If the provided type is an array of references, this function returns the reference information
 *  for an individual array element.
 */
export function GetTypeReference(type: Type): Reference | undefined {
    if (typeof type !== "object") return
    if (type.reference) return type.reference
    if (IsArrayType(type)) return GetTypeReference(type.array)
}

/**
 * Represents the ECMAScript symbol type. This is not supported in JSON or
 * OpenAPI but can some times appear during reflection.
 *
 * A typical scenario is `[(keyof Foo)]`  which becomes
 * `[(string | number | * symbol)]` during reflection.
 *
 * This is simplified to `[(string | number)]` during type decoding.
 */
export type SymbolType = "symbol"

export type StringType =
    | "string"
    | (TypeBase & {
          /** A literal string value, or `null` to match
    any string */
          string: string | null
      })
export type NumberType =
    | "number"
    | "integer"
    | (TypeBase & {
          /** A literal number value, or `null` to match any number */
          number: number | null
          minValue?: number
          maxValue?: number
          integer?: boolean
      })
export type IntegerType =
    | "integer"
    | (NumberType & {
          integer: true
      })
export type BooleanType =
    | "boolean"
    | (TypeBase & {
          /** A literal boolean value, or `null` to match any boolean */
          boolean: boolean | null
      })

export type ParameterType = TypeBase & {
    param: string
}

export type NullType =
    | "null"
    | (TypeBase & {
          null: null
      })
export type UndefinedType =
    | "undefined"
    | (TypeBase & {
          undefined: null
      })
export type AnyType =
    | "any"
    | (TypeBase & {
          any: null
      })

export type UnknownType =
    | "unknown"
    | (TypeBase & {
          unknown: null
      })
export type FunctionType =
    | "function"
    | (TypeBase & {
          function: null
      })
export type DateType = "date" | (TypeBase & { date: null })

export type UnionType = TypeBase & {
    union: Type[]
    /** If set, this indicates that this is a union of `undefined` and the type specified by this
     *  field. Setting this can speed up discrimination as the union does not need to be searched.
     */
    ifDefinedCache?: Type
}
export type IntersectionType = TypeBase & { intersection: Type[] }
export type ObjectType = TypeBase & {
    props: Property[]
    isClass?: boolean
    super?: ObjectType
    additionalProps?: Type
}

export type Property = {
    name: string
    type: Type
    optional?: boolean

    /** Whether the property is declared as `readonly` in TypeScript.
     *
     *  In Studio, the field can be read-only for other reasons as well, e.g. if
     *  the property is locked for editing by humans (e.g. in Studio or WYSIWYG).
     */
    isReadonly?: boolean

    /** Whether this property is locked for editing by humans (e.g. in Studio or
     * WYSIWYG). This is implicitly `true` if `isReadonly` is `true`, but can
     * also be enabled explicitly on properties or entire types by using the
     * `@locked` attribute.
     */
    isLocked?: boolean

    description?: Localized<string>

    tags?: Tags

    /** A placeholder value to display when this property is lacking a value. */
    placeholder?: any
}

export type ArrayType = TypeBase & { array: Type }
export type BufferType = TypeBase & { buffer: null }
export type PromiseType = TypeBase & { promise: Type }
export type AsyncGeneratorType = TypeBase & { generator: Type; returns: Type; next: Type }
export function AsyncGeneratorType(generator: Type, returns: Type, next: Type): AsyncGeneratorType {
    return { generator, returns, next }
}
export function IsAsyncGeneratorType(t: Type): t is AsyncGeneratorType {
    return typeof t === "object" && "generator" in t
}

export type VoidType = "void" | (TypeBase & { void: null })
export type NeverType = "never" | (TypeBase & { never: null })

const typeToStringCache = new WeakMap<Type & object, string>()

export function TypeToString(t: Type): string {
    if (typeof t === "string") return t

    let cached = typeToStringCache.get(t)
    if (cached === undefined) {
        cached = TypeToStringUncached(t, true, false)
        typeToStringCache.set(t, cached)
    }
    return cached
}

export type TypeToStringOptions = {
    aliasEncounter?: (type: Type) => void
    preserveOpaques?: string[]
    emitTypeArgs?: boolean
}

export function TypeToStringUncached(
    t: Type,
    alias: boolean,
    docs: boolean,
    options?: TypeToStringOptions
): string {
    if (typeof t === "string") {
        return t
    }

    if (t.opaque && t.alias) {
        if (IsOpaqueStringType(t) && "precision" in t && t.precision) {
            if (options?.aliasEncounter) options?.aliasEncounter(t)
            return `${t.alias}<"${t.precision}">`
        }

        if (options?.preserveOpaques?.includes(t.alias)) {
            return t.alias + '<"' + t.opaque + '">'
        }
    }

    if (
        t.typeArgs?.length &&
        alias &&
        GetTypeAlias(t) !== undefined &&
        options?.emitTypeArgs !== false
    ) {
        return (
            TypeToStringUncached(t, alias, docs, { ...options, emitTypeArgs: false }) +
            "<" +
            t.typeArgs.map((b) => TypeToStringUncached(b, true, docs, options)) +
            ">"
        )
    }

    if (alias && t.alias && t.alias !== "Array") {
        if (options?.aliasEncounter) options?.aliasEncounter(t)
        return t.alias
    } else if (IsStringType(t)) {
        return t.string == null ? "string" : JSON.stringify(t.string)
    } else if (IsNumberType(t)) {
        return t.number === null ? "number" : "" + t.number
    } else if (IsBooleanType(t)) {
        return t.boolean === null ? "boolean" : "" + t.boolean
    } else if (IsUndefinedType(t)) {
        return "undefined"
    } else if (IsNullType(t)) {
        return "null"
    } else if (IsAnyType(t)) {
        return "any"
    } else if (IsUnionType(t)) {
        return (
            "(" + t.union.map((x) => TypeToStringUncached(x, true, docs, options)).join(" | ") + ")"
        )
    } else if (IsIntersectionType(t)) {
        return (
            "(" +
            t.intersection.map((x) => TypeToStringUncached(x, true, docs, options)).join(" & ") +
            ")"
        )
    } else if (IsArrayType(t)) {
        return TypeToStringUncached(t.array, true, docs, options) + "[]"
    } else if (IsObjectType(t)) {
        return (
            "{ " +
            t.props
                .map(
                    (x) =>
                        (docs && x.description?.en ? "/** " + x.description?.en + "*/\n" : "") +
                        JSON.stringify(x.name) +
                        (x.optional ? "?" : "") +
                        ": " +
                        TypeToStringUncached(x.type, true, docs, options)
                )
                .join(",\n") +
            (t.additionalProps
                ? `\n[key: string]: ${TypeToStringUncached(t.additionalProps, true, docs, options)}`
                : "") +
            " }"
        )
    } else if (IsDateType(t)) {
        return "Date"
    } else if (IsVoidType(t)) {
        return "void"
    } else if (IsParameterType(t)) {
        return t.param
    } else if (IsNeverType(t)) {
        return "never"
    } else if (IsUnknownType(t)) {
        return "unknown"
    } else if (IsPromiseType(t)) {
        return "Promise<" + TypeToString(t.promise) + ">"
    } else if (IsAsyncGeneratorType(t)) {
        return "AsyncIterable<" + TypeToString(t.generator) + ">"
    } else if (IsFileType(t)) {
        return 'File<"' + t.mimeType + '">'
    } else {
        throw new Error("Cannot stringify " + t)
    }
}

/**
 * Returns the name of the model the given type is declared in.
 *
 * If `undefined` is returned, this indicates the type is not declared in a
 * model folder, or there is not enough reflection information to determine
 * the model name.
 */
export function GetTypeModel(type: Type): string | undefined {
    if (typeof type !== "object") return
    if (!type.sourceFile) return
    if (!type.sourceFile.startsWith("/models/")) return

    return type.sourceFile.split("/")[2]
}

const typePropsCache = new Map<Type, readonly Property[]>()
const emptyProps: readonly Property[] = Object.freeze([])

/** Returns the properties of the given type, potentially flattening unions and intersections
 * recursively. Caches the results for performance. */
export function GetTypeProps(
    type: Type,
    /** Optionally provide a value. If the type is a union, this will be used to attempt to
     * discriminate the union to get a more precise property set. */
    value?: any
): readonly Property[] {
    if (IsObjectType(type)) {
        if (!(type.props instanceof Array)) throw new Error()
        return type.props
    }
    if (IsIntersectionType(type)) {
        const props = typePropsCache.get(type)
        if (props) return props

        const newProps = type.intersection.map(GetTypeProps).reduce((a, b) => a.concat(b), [])
        typePropsCache.set(type, newProps)
        return newProps
    } else if (IsUnionType(type)) {
        if (value) {
            const discType = Discriminate(value, type)
            if (discType !== type) return GetTypeProps(discType)
        }

        const props = typePropsCache.get(type)
        if (props) return props

        const u = type.union.map((x) => GetTypeProps(x))
        if (u.length === 0) return emptyProps

        const newProps = u[0].filter((x) => u.every((y) => y.some((z) => z.name === x.name)))
        typePropsCache.set(type, newProps)
        return newProps
    } else {
        return emptyProps
    }
}

const areTypesAssignableCache = new WeakMap<Type & object, WeakMap<Type & object, boolean>>()

/** Checks whether a value of type `source` is assignable to type `dest`.
 *
 *  The result is cached based on the type objects. The types can not be
 *  modified after this function is used.
 */
export function AreTypesAssignable(dest: Type, source: Type): boolean {
    if (source === dest) return true
    if (source === "any") return true
    if (dest === "any") return true
    if (typeof dest === "string") return dest === source

    let cached: WeakMap<Type & object, boolean> | undefined
    if (typeof source === "object") {
        let cached = areTypesAssignableCache.get(dest)
        if (cached) {
            const value = cached.get(source)
            if (value !== undefined) {
                return value
            }
        } else {
            cached = new Map<Type, boolean>()
            areTypesAssignableCache.set(dest, cached)
        }
    }

    const result = AreTypesAssignableUncached(dest, source)

    if (cached && typeof source === "object") {
        cached.set(source, result)
    }
    return result
}

function AreTypesAssignableUncached(dest: Type, source: Type) {
    if (IsUnionType(source)) {
        return source.union.every((x) => AreTypesAssignable(dest, x))
    }
    if (IsUnionType(dest)) {
        return dest.union.some((d) => AreTypesAssignable(d, source))
    }
    if (IsFileType(source) && IsFileType(dest)) {
        return source.mimeType === dest.mimeType
    }

    if (typeof source === "object" && typeof dest === "object") {
        if (dest.opaque !== source.opaque) return false
        if (source.alias && source.alias === dest.alias) return true
        if (source.alias && dest.alias && source.alias !== dest.alias) return false
    }

    if (IsAnyType(dest)) {
        return true
    }

    if (IsDateType(dest)) {
        return IsDateType(source)
    }

    if (IsIntersectionType(dest)) {
        return dest.intersection.every((d) => AreTypesAssignable(d, source))
    }

    if (IsFileType(dest)) {
        return (
            IsFileType(source) &&
            (source.mimeType.startsWith(dest.mimeType) || dest.mimeType.startsWith(source.mimeType))
        )
    }

    if (IsStringType(dest)) {
        if (!IsStringType(source)) return false
        if (dest === "string") return true
        if (dest.string === null) return true
        if (typeof source !== "object") return false
        if (source.string === null) return false
        else return dest.string === source.string
    }
    if (IsNumberType(dest)) {
        if (!IsNumberType(source)) return false
        if (dest === "number") return true
        if (dest === "integer") return IsIntegerType(source)
        if (dest.number === null) return true
        if (typeof source !== "object") return false
        if (source.number === null) return false
        else return dest.number === source.number
    }
    if (IsBooleanType(dest)) {
        if (!IsBooleanType(source)) return false
        if (dest === "boolean") return true
        if (dest.boolean === null) return true
        if (typeof source !== "object") return false
        if (source.boolean === null) return false
        else return dest.boolean === source.boolean
    }
    if (IsNullType(dest)) {
        return IsNullType(source)
    }
    if (IsUndefinedType(dest)) {
        return IsUndefinedType(source)
    }
    if (IsArrayType(dest)) {
        if (!IsArrayType(source)) return false
        return AreTypesAssignable(dest.array, source.array)
    }
    if (IsPromiseType(dest)) {
        if (!IsPromiseType(source)) return false
        return AreTypesAssignable(dest.promise, source.promise)
    }
    if (IsObjectType(dest)) {
        if (!IsObjectType(source)) return false
        return dest.props.every((pd) => {
            const ps = source.props.find((p) => p.name === pd.name)
            if (!ps) {
                return pd.optional
            }
            return AreTypesAssignable(pd.type, ps.type)
        })
    }
    if (IsParameterType(dest)) {
        if (!IsParameterType(source)) return false
        return dest.param === source.param
    }
    if (IsUnknownType(dest)) {
        return false
    }
    if (IsVoidType(dest)) {
        if (IsVoidType(source)) return true
        if (IsUndefinedType(source)) return true
        return false
    }
    if (IsFunctionType(dest)) {
        return false // TODO
    }
    if (IsNeverType(dest)) {
        return false
    }

    throw new Error("Unhandled type: " + TypeToString(dest))
}

/** Checks that a is assignable to b, and b is assignable to a.
 *
 *  Note that this does not mean tha the types are semantically equal. For example, if both types
 *  only have optional fields, this function will return `true`.
 *
 *  This is probably not the function you want to use.
 */
export function AreTypesAssignableBothWays(a: Type, b: Type) {
    return AreTypesAssignable(a, b) && AreTypesAssignable(b, a)
}

export type TypeDiagnostic = {
    error: string
    path: string
    subErrors?: TypeDiagnostic[]
    /** The fields implicated in this error, if relevant. */
    fields?: string[]
}

export function TypeDiagnosticToString(e: TypeDiagnostic, indent = 0): string {
    if (e.subErrors && e.subErrors.length === 1) {
        return TypeDiagnosticToString(e.subErrors[0], indent)
    }
    return (
        " ".repeat(indent) +
        e.path +
        ": " +
        e.error +
        (e.subErrors && e.subErrors.length
            ? e.subErrors.map((x) => "\n" + TypeDiagnosticToString(x, indent + 1)).join("")
            : "")
    )
}

/** Performs default repairs on values that have been serialized to JSON and back.
 *
 *  These are the default conversions:
 *  - `string`s back to `Date`s if `Date` is the expected type.
 *  - `null` to `undefined` if `undefined` is the expected type
 *  - Undo URL encoding if `Image` or `File` is the expected type (UndoImageToUrl)
 */
export function RepairDefaultTypes(v: any, type: Type) {
    return RepairTypes(v, type, [
        (x, t) => (AreTypesAssignable(t, DateType) && typeof x === "string" ? new Date(x) : x),
        (x, t) => (AreTypesAssignable(t, UndefinedType) && x === null ? undefined : x),
    ])
}

export function RepairTypes(v: any, type: Type, rules: RepairRule[]) {
    if (IsAnyType(type)) {
        return v
    }

    if (rules.length) {
        for (const rule of rules) {
            v = rule(v, type)
        }
    }

    if (IsUnionType(type)) {
        const dt = Discriminate(v, type)

        if (IsUnionType(dt)) {
            if (dt.union.find((x) => MatchesType(RepairTypes(v, x, rules), x) === true)) {
                return v
            }
        } else {
            type = dt
        }
    }
    if (IsIntersectionType(type)) {
        for (const t of type.intersection) {
            v = RepairTypes(v, t, rules)
        }
    }

    if (IsObjectType(type) && v && typeof v === "object") {
        type.props.forEach((p) => {
            if (p.name in v) {
                v[p.name] = RepairTypes(v[p.name], p.type, rules)
            }
        })
    }
    if (IsArrayType(type) && v instanceof Array) {
        for (let i = 0; i < v.length; i++) {
            v[i] = RepairTypes(v[i], type.array, rules)
        }
    }
    if (IsFileType(type)) {
        if ((typeof v === "string" && v.startsWith("http://")) || v.startsWith("https://")) {
            return UndoImageToUrl(v)
        }
    }

    return v
}

export type RepairRule = (obj: any, type: Type) => any

function typeOf(v: any) {
    if (v === null) return "null"
    return typeof v
}

const validatorCache = new Map<Type, Function>()

/** Returns the validator function of a type, if defined.
 *
 *  In Node, this returns the original function (to be compatible with code coverage tools).
 *
 *  On the client side, this evaluates the function from the source code found in reflection.
 */
export function GetTypeValidator(type: Type): Function | undefined {
    if (typeof type === "object") {
        const alias = GetTypeAlias(type)
        if (type.validator) {
            if (IsNode() && alias) {
                return TypeValidator.functions.find((t) => t.name === alias)
            } else {
                let val = validatorCache.get(type)
                if (!val) {
                    // eslint-disable-next-line no-eval
                    val = eval("(" + type.validator + ")") as Function
                    validatorCache.set(type, val)
                }
                return val
            }
        }
    }
}

/** If the type contains a validator function, it executes the validator
 * function and returns the resulting (possibly corrected) value.
 *
 * If the type does not contain a validator function, it returns the value
 * unchanged.
 *
 * If an error is encountered in validation, it throws an error.
 * */
export function ValidateType<T>(value: T, type: Type): T {
    if (typeof type === "object" && type.validator) {
        const validator = GetTypeValidator(type)
        if (validator) return validator(value) as T
    }
    return value
}

/**
 * Checks if a value matches a type. Does not generate error messages, so it is
 * faster in the case where error messages are not needed.
 *
 * If you want error messages to be generated, use `MatchesType` instead.
 */
export function MatchesTypeTurbo(value: any, type: Type, validate = false): boolean {
    // Fast paths for common cases
    const t = typeof value
    if (t === type) return true

    if (typeof type === "object") {
        if (validate && type.validator) {
            try {
                value = ValidateType(value, type)
            } catch (e: any) {
                return false
            }
        }

        switch (t) {
            case "string":
                if ((type as any).string === null) return true
                if ((type as any).string === value) return true
                break
            case "number":
                if (IsNumberType(type)) {
                    if (type.minValue !== undefined) break
                    if (type.maxValue !== undefined) break
                    if (type.integer) break
                }
                if ((type as any).number === null) return true
                if ((type as any).number === value) return true
                break
            case "boolean":
                if ((type as any).boolean === null) return true
                if ((type as any).boolean === value) return true
                break
        }
    } else if (typeof type === "string") {
        switch (type) {
            case "string":
                return t === "string"
            case "number":
                return t === "number"
            case "integer":
                return t === "number" && Number.isInteger(value)
            case "date":
                return t === "object" && value instanceof Date
            case "function":
                return t === "function"
            case "void":
                return t === "undefined"
            case "symbol":
                return t === "symbol"
            case "boolean":
                return t === "boolean"
            case "null":
                return value === null
            case "undefined":
                return value === undefined
            case "any":
                return true
            case "unknown":
            case "never":
                return false
        }
    }

    // Fast path for successful matches using fast JIT'ed checker
    if (typeof type === "object" && MatchesTypeJIT(type, value, validate)) {
        return true
    }
    return false
}

export function MatchesType(
    value: any,
    type: Type,
    /** If true, the function will return on the first error.
     *  If false, the function will find all errors before returning.
     */
    fast: boolean = true,
    /** If true, this function will report overposting/superfluous props as errors.
     *  If false, superfluous props will be ignored.
     */
    strict = false,
    /** If true, this function will include validator functions as part of asserting a value's
     *  conformance to a type. If false, validator functions will be ignored.
     */
    validate = true,
    path = ""
): true | TypeDiagnostic {
    const turboMatch = MatchesTypeTurbo(value, type, validate)
    if (turboMatch) return true

    if (typeof type === "object") {
        if (validate && type.validator) {
            try {
                value = ValidateType(value, type)
            } catch (e: any) {
                return { error: e.message, fields: e.fields, path }
            }
        }
    }

    // Fast matcher failed, producing proper error message
    const t = typeof value

    if (typeof t === "string") {
        switch (type) {
            case "string":
                return t === "string" ? true : { error: "Expected string, got " + t, path }
            case "number":
                return t === "number" ? true : { error: "Expected number, got " + t, path }
            case "boolean":
                return t === "boolean" ? true : { error: "Expected boolean, got " + t, path }
            case "null":
                return value === null ? true : { error: "Expected null, got " + t, path }
            case "undefined":
                return value === undefined ? true : { error: "Expected undefined, got " + t, path }
        }
    }

    if (IsObjectType(type)) {
        if (value === null) {
            return { error: "Expected object, got null", path }
        }
        if (t !== "object") {
            return { error: "Expected object, got " + typeOf(value), path }
        }

        const props = type.props

        const getSuperfluousProps = () =>
            Object.keys(value).filter((x) => !props.some((y) => x === y.name))

        const subErrors: TypeDiagnostic[] = []

        for (const p of props) {
            const optional = p.optional || AreTypesAssignable(p.type, "undefined")

            if (optional && value[p.name] === undefined) {
                continue
            }
            if (!optional && !(p.name in value)) {
                subErrors.push({
                    error: "Missing required property " + p.name,
                    subErrors: [],
                    path,
                })
                if (fast) break
            }
            const res = MatchesType(
                value[p.name],
                p.type,
                fast,
                strict,
                validate,
                path ? path + "." + p.name : p.name
            )
            if (res !== true) {
                subErrors.push({
                    error: "Property '" + p.name + "' did not match",
                    subErrors: [res],
                    path,
                })
                if (fast) break
            }
        }
        if (strict) {
            const extraProps = getSuperfluousProps()
            if (extraProps.length > 0) {
                subErrors.push({
                    error: "The following props were present but not expected",
                    subErrors: extraProps.map((x) => ({ error: x, path })),
                    path,
                })
            }
        }

        if (subErrors.length > 0) {
            return {
                error: "Did not match object type " + TypeToString(type),
                subErrors,
                path,
            }
        }

        return true
    } else if (IsArrayType(type)) {
        if (!(value instanceof Array))
            return { error: "Expected array, got " + typeOf(value), path }

        const subErrors: TypeDiagnostic[] = []

        const len = value.length
        for (let i = 0; i < len; i++) {
            const res = MatchesType(
                value[i],
                type.array,
                fast,
                strict,
                validate,
                path + "[" + i + "]"
            )
            if (res !== true) {
                subErrors.push({
                    error: "Element at index " + i + " did not match",
                    subErrors: [res],
                    path,
                })
                if (fast) break
            }
        }

        if (subErrors.length === 1) {
            return {
                error: "An item does not match expected type " + TypeToString(type.array),
                subErrors,
                path,
            }
        } else if (subErrors.length > 1 && subErrors.length < value.length) {
            return {
                error:
                    subErrors.length +
                    " out of " +
                    value.length +
                    " items do not match expected type " +
                    TypeToString(type.array),
                subErrors,
                path,
            }
        } else if (subErrors.length) {
            return {
                error: "None of the items match expected type " + TypeToString(type.array),
                subErrors,
                path,
            }
        }

        return true
    } else if (IsDateType(type)) {
        return (
            (t === "object" && value instanceof Date) || {
                error: "Expected Date, got " + value,
                path,
            }
        )
    } else if (IsAnyType(type)) {
        return true
    } else if (IsNullType(type)) {
        return value === null ? true : { error: "Expected null, got " + typeOf(value), path }
    } else if (IsUnionType(type)) {
        const d = Discriminate(value, type)
        if (d !== type) {
            const res = MatchesType(
                value,
                d,
                fast,
                strict,
                true,
                "(" + path + " as " + TypeToString(d) + ")"
            )
            if (res === true) return true
            else
                return {
                    error: "Did not match discriminated type " + TypeToString(d),
                    subErrors: [res],
                    path,
                }
        } else {
            const results = type.union.map((x) =>
                MatchesType(value, x, fast, strict, true, TypeToString(x))
            )

            if (results.some((x) => x === true)) {
                return true
            } else {
                return {
                    error: "Did not match any types in the union " + TypeToString(type),
                    subErrors: results.filter((x) => typeof x === "object"),
                    path,
                }
            }
        }
    } else if (IsIntersectionType(type)) {
        const subErrors: TypeDiagnostic[] = []
        for (const x of type.intersection) {
            const err = MatchesType(value, x, fast, false, validate)
            if (err !== true) {
                subErrors.push(err)
            }
        }
        if (subErrors.length === 0 && strict && typeof value === "object" && value !== null) {
            const props = GetTypeProps(type)
            Object.keys(value).forEach((x) => {
                if (!props.find((y) => y.name === x)) {
                    subErrors.push({
                        error:
                            "Property '" + x + "' was not expected in type " + TypeToString(type),
                        path,
                    })
                }
            })
        }

        if (subErrors.length > 0) {
            return {
                error: "Did not match intersection " + TypeToString(type),
                subErrors,
                path,
            }
        }
        return true
    } else if (IsVoidType(type)) {
        return value === undefined ? true : { error: "Expected void, got a value", path }
    } else if (IsUnknownType(type)) {
        return { error: "No value is compatible with 'unknown'", path }
    } else if (IsUndefinedType(type)) {
        return t === "undefined"
            ? true
            : { error: "Expected undefined, got " + typeOf(value), path }
    } else if (IsFileType(type)) {
        if (t !== "string")
            return { error: "Expected file handle, got " + JSON.stringify(value), path }
        if (value.startsWith("http://") || value.startsWith("https://"))
            return { error: "Expected file handle, got URL: " + value, path }
        if (!Uuid.validate(value))
            return { error: "Expected UUID file handle, got " + JSON.stringify(value), path }

        return true
    } else if (IsStringType(type)) {
        if (typeof type === "object") {
            if (type.string !== null && value !== type.string) {
                return {
                    error: "Expected '" + type.string + "', got " + JSON.stringify(value),
                    path,
                }
            }
            if (type.string === null) {
                if (t !== "string")
                    return {
                        error: "Expected string, got " + typeOf(value),
                        path,
                    }
            }
        }

        return true
    } else if (IsNumberType(type)) {
        if (type === "integer" || (typeof type === "object" && type.integer)) {
            if ((value | 0) !== value) {
                return { error: "Expected integer, got " + JSON.stringify(value), path }
            }
        }
        if (typeof type === "object") {
            if (type.number !== null) {
                if (value !== type.number)
                    return {
                        error: "Expected " + type.number + ", got " + typeOf(value),
                        path,
                    }
            }
            if (type.maxValue !== undefined) {
                if (value > type.maxValue)
                    return {
                        error: "Maximum value is " + type.maxValue + ", got " + value,
                        path,
                    }
            }
            if (type.minValue !== undefined) {
                if (value < type.minValue)
                    return {
                        error: "Minimum value is " + type.minValue + ", got " + value,
                        path,
                    }
            }
        }
        if (t !== "number") return { error: "Expected number, got " + typeOf(value), path }
        return true
    } else if (IsBooleanType(type)) {
        if (t !== "boolean") return { error: "Expected boolean, got " + typeOf(value), path }
        if (typeof type === "object" && type.boolean !== null) {
            if (type.boolean === value) return true
            else return { error: "Expected '" + type.boolean + "', got " + value, path }
        } else return true
    }

    throw new Error("Unhandled type: " + TypeToString(type))
}

export function EnumerateTypeOptions(type: Type, result: Type[] = []): Type[] {
    if (IsUnionType(type)) {
        type.union.forEach((t) => EnumerateTypeOptions(t, result))
    } else {
        result.push(type)
    }
    return result
}

const isStringLiteralUnionCache = new Map<UnionType, Map<string, StringLiteralType> | false>()

let discriminateDepth = 0
export function Discriminate(value: any, union: UnionType): Type {
    if (union.ifDefinedCache) {
        if (value === undefined) return "undefined"
        return union.ifDefinedCache
    } else if (union.union.length === 2) {
        const t = union.union.filter((x) => !IsUndefinedType(x))
        if (t.length === 1) {
            union.ifDefinedCache = t[0]
            if (value === undefined) return "undefined"
            return union.ifDefinedCache
        }
    }

    const alias = GetTypeAlias(union)
    const plugin = alias && Discriminate.plugins.get(alias)
    if (plugin) {
        return plugin(value, union)
    }

    if (discriminateDepth > 8) {
        console.log("Warning - deep type recursion, unable to discriminate " + TypeToString(union))
        // Probably a recursive definition - unable to discriminate
        return union
    }

    discriminateDepth++
    try {
        if (typeof value === "object" && value !== null) {
            let d = discriminatorCache.get(union)
            if (!d) {
                const u = FlattenTypeIntersections(union) as UnionType
                d = FindDiscriminator(u)
                if (d) discriminatorCache.set(union, d)
            }
            if (d) {
                const key = value[d.prop]
                return d.mapping[key] || union
            }
        }

        // A common case that becomes a performance issue is to discriminate large string unions
        // (enums). This optimizes that case.
        let stringLiteralUnion = isStringLiteralUnionCache.get(union)
        if (stringLiteralUnion === undefined) {
            stringLiteralUnion = union.union.every(IsStringLiteral)
                ? new Map(union.union.map((t: StringLiteralType) => [t.string, t]))
                : false
            isStringLiteralUnionCache.set(union, stringLiteralUnion)
        }
        if (stringLiteralUnion) {
            const stringLiteral = stringLiteralUnion.get(value)
            if (stringLiteral) return stringLiteral
        }

        const types = union.union.filter((x) => MatchesTypeTurbo(value, x, false))
        if (types.length === 1) {
            return types[0]
        }

        // Try resolving the ambiguous match by matching again in strict mode (#75)
        const types2 = union.union.filter((x) => MatchesTypeTurbo(value, x, false))
        if (types2.length === 1) {
            return types2[0]
        }
        return union
    } finally {
        discriminateDepth--
    }
}
Discriminate.plugins = new Map<string, DiscriminatePlugin<any>>()

export type DiscriminatePlugin<T> = (value: T, union: UnionType) => Type
/** Installs a plugin that can provide a faster way to discriminate a type with the given alias.
 *
 * Should be provided for frequently used types that are not trivially discriminated unions.
 * */
export function DiscriminatePlugin<T>(alias: string, discriminate: DiscriminatePlugin<T>): void {
    Discriminate.plugins.set(alias, discriminate)
}

const discriminatorCache = new WeakMap<UnionType, Discriminator>()

/** Finds the discriminator for the given union.
 *
 *  Caches the result in the `discriminator` field of the union.
 */
export function FindDiscriminator(u: UnionType) {
    let discriminator = discriminatorCache.get(u)
    if (!discriminator) {
        const t = u.union[0]
        if (IsObjectType(t)) {
            discriminator = t.props
                .map((p) => buildDiscriminatorProp(u, { prop: p.name, mapping: {} }))
                .find((x) => !!x)

            if (discriminator) {
                discriminatorCache.set(u, discriminator)
            }
        }
    }

    return discriminator
}

export type Discriminator = { prop: string; mapping: { [value: string]: Type } }

function buildDiscriminatorProp(t: Type, context: Discriminator): Discriminator | undefined {
    if (IsObjectType(t)) {
        const p = t.props.find((x) => x.name === context.prop)
        return p && buildDiscriminatorMapping(p.type, t, context)
    } else if (IsUnionType(t)) {
        for (const u of t.union) {
            if (!buildDiscriminatorProp(u, context)) {
                return undefined
            }
        }
        return context
    }
}
function buildDiscriminatorMapping(
    propType: Type,
    targetType: Type,
    context: Discriminator
): Discriminator | undefined {
    if (IsStringLiteral(propType)) {
        context.mapping[propType.string] = targetType
        return context
    } else if (IsUnionType(propType)) {
        if (propType.union.every((u) => buildDiscriminatorMapping(u, targetType, context))) {
            return context
        }
    }
}

const flattenedTypesCache = new WeakMap<Type & object, Type>()

export function FlattenTypeIntersections(type: Type) {
    if (typeof type === "string") return type
    let flat = flattenedTypesCache.get(type)
    if (!flat) {
        flat = FlattenTypeIntersectionsUncached(type)
        flattenedTypesCache.set(type, flat)
    }

    return flat
}

function FlattenTypeIntersectionsUncached(type: Type) {
    let t = typeof type === "string" ? type : CloneGraph(type)
    let keepGoing = true
    while (keepGoing) {
        keepGoing = false
        t =
            new (class extends Visitor {
                override intersection(i: IntersectionType) {
                    // (T & (T | undefined)) -> T
                    if (i.intersection.length === 2) {
                        if (
                            AreTypesAssignableBothWays(
                                i.intersection[0],
                                UnionType(i.intersection[1], UndefinedType)
                            )
                        ) {
                            return i.intersection[1]
                        }
                        if (
                            AreTypesAssignableBothWays(
                                i.intersection[1],
                                UnionType(i.intersection[0], UndefinedType)
                            )
                        ) {
                            return i.intersection[0]
                        }
                    }

                    if (i.intersection.every(IsObjectType)) {
                        keepGoing = true
                        const props = i.intersection
                            .map((x) => x.props)
                            .reduce((a, b) => a.concat(b), [])
                            // Shallow copy the props, as we will be modifying their optionality
                            .map((p) => ({ ...p }))

                        // If properties occur multiple times in the intersection,
                        // compute the intersection of the property types and optionality
                        for (let n = 0; n < props.length; n++) {
                            for (let m = n + 1; m < props.length; m++) {
                                if (props[m].name === props[n].name) {
                                    if (!props[m].optional) {
                                        props[n].optional = false
                                    }
                                    props[n].type = FlattenTypeIntersections(
                                        IntersectionType(props[n].type, props[m].type)
                                    )
                                    props.splice(m, 1)
                                }
                            }
                        }

                        const objType: ObjectType = {
                            props,
                            additionalProps: i.intersection.find((x) => x.additionalProps)
                                ?.additionalProps,
                            alias: i.alias,
                            documentation: i.documentation,
                        }
                        return objType
                    }
                }
            })().type(t) || t
    }
    return t
}

export function IsTypeOrUndefined<T extends Type>(
    type: Type,
    isType: (t: Type) => t is T
): T | undefined {
    if (IsUnionType(type) && type.union.length === 2) {
        if (isType(type.union[0]) && IsUndefinedType(type.union[1])) return type.union[0]
        else if (isType(type.union[1]) && IsUndefinedType(type.union[0])) return type.union[1]
    }
}

export function IsStringOrUndefinedType(type: Type) {
    return IsTypeOrUndefined(type, IsStringType)
}

export function IsNumberOrUndefinedType(type: Type) {
    return IsTypeOrUndefined(type, IsNumberType)
}

/**
 * Visits all the types reachable from a given type.
 */
export function VisitType(
    type: Type,
    visitor: (t: Type, p: string[]) => void,
    visitedSet = new Set<Type>(),
    path: string[] = []
) {
    if (visitedSet.has(type)) return
    visitedSet.add(type)

    visitor(type, path)

    if (typeof type === "object") {
        type.typeArgs?.forEach((x) => VisitType(x, visitor, visitedSet, path))
    }

    if (IsObjectType(type)) {
        type.props.forEach((x) => VisitType(x.type, visitor, visitedSet, [...path, x.name]))
        if (type.additionalProps)
            VisitType(type.additionalProps, visitor, visitedSet, [...path, "additionalProps"])
    } else if (IsArrayType(type)) {
        VisitType(type.array, visitor, visitedSet, path)
    } else if (IsUnionType(type)) {
        const disc = FindDiscriminator(type)
        type.union.forEach((x, i) => {
            const dp = GetTypeProps(x).find((p) => p.name === disc?.prop)
            const name = dp ? (IsStringLiteral(dp.type) ? dp.type.string : undefined) : undefined
            VisitType(x, visitor, visitedSet, [...path, name ?? GetTypeAlias(x) ?? i.toString()])
        })
    } else if (IsIntersectionType(type)) {
        type.intersection.forEach((x, i) =>
            VisitType(x, visitor, visitedSet, [...path, GetTypeAlias(x) || i.toString()])
        )
    }
}
export function ContainsType(type: Type, predicate: (t: Type) => boolean): boolean {
    let res = false
    VisitType(type, (x) => {
        if (predicate(x)) res = true
    })
    return res
}

/**
 * Visits all the types reachable from a given type, replacing types in-place
 * using the replacer function.
 */
export function ReplaceTypes(
    t: Type,
    replacer: (type: Type) => Type,
    visitedSet = new Map<Type, Type>(),
    /**
     * Whether to continue recursing after a successful replacement.
     *
     * This can cause infinite recursion if the replacer function in some cases.
     * Defaults to true, can be turned off if problematic.
     */
    recurseAfterReplace = true
) {
    const visited = visitedSet.get(t)
    if (visited) return visited

    const type = replacer(t)
    visitedSet.set(t, type)

    if (type !== t && !recurseAfterReplace) {
        return type
    }

    if (typeof type === "object") {
        const args = type.typeArgs
        if (args)
            for (let i = 0; i < args.length; i++)
                args[i] = ReplaceTypes(args[i], replacer, visitedSet, recurseAfterReplace)
    }

    if (IsObjectType(type)) {
        type.props.forEach(
            (x, i) =>
                (type.props[i].type = ReplaceTypes(
                    x.type,
                    replacer,
                    visitedSet,
                    recurseAfterReplace
                ))
        )
        if (type.additionalProps)
            type.additionalProps = ReplaceTypes(
                type.additionalProps,
                replacer,
                visitedSet,
                recurseAfterReplace
            )
    } else if (IsArrayType(type)) {
        type.array = ReplaceTypes(type.array, replacer, visitedSet, recurseAfterReplace)
    } else if (IsUnionType(type)) {
        type.union.forEach(
            (x, i) => (type.union[i] = ReplaceTypes(x, replacer, visitedSet, recurseAfterReplace))
        )
    } else if (IsIntersectionType(type)) {
        type.intersection.forEach(
            (x, i) =>
                (type.intersection[i] = ReplaceTypes(x, replacer, visitedSet, recurseAfterReplace))
        )
    }

    return type
}

/**
 * Visits all the values in an object graph with their resolved types, allowing
 * you to return a new value by inspecting the value and its type.
 */
export function ReplaceValuesByType(
    value: any,
    type: Type,
    /** Returns the new value to replace the existing one with.
     *  Return the incoming `value` to leave it unchanged.
     */
    replacer: (v: any, t: Type, pathToProp: (string | number)[]) => any,
    visitedObjects = new Set<object>(),
    path: (string | number)[] = []
): any {
    const dt = SubstituteAndDiscriminate(value, type)

    if (dt !== type) {
        return ReplaceValuesByType(value, dt, replacer, visitedObjects, path)
    }

    value = replacer(value, type, path)

    if ((IsObjectType(type) || IsIntersectionType(type)) && typeof value === "object") {
        if (visitedObjects.has(value)) return value
        visitedObjects.add(value)

        const props = GetTypeProps(type)

        props.forEach((x) => {
            if (x.name in value) {
                value[x.name] = ReplaceValuesByType(
                    value[x.name],
                    x.type,
                    replacer,
                    visitedObjects,
                    [...path, x.name]
                )
            }
        })
        if (IsObjectType(type)) {
            const { additionalProps } = type
            if (additionalProps) {
                Object.keys(value).forEach((key) => {
                    if (!type.props.some((x) => x.name === key)) {
                        value[key] = ReplaceValuesByType(
                            value[key],
                            additionalProps,
                            replacer,
                            visitedObjects,
                            [...path, key]
                        )
                    }
                })
            }
        }
    } else if (IsArrayType(type) && value instanceof Array) {
        if (visitedObjects.has(value)) return value
        visitedObjects.add(value)

        value.forEach(
            (x: any, i: number) =>
                (value[i] = ReplaceValuesByType(x, type.array, replacer, visitedObjects, [
                    ...path,
                    i,
                ]))
        )
    }
    return value
}

/**
 * Visits all the fields in an object graph with their resolved types.
 *
 * This includes visiting array elements, where the property names will be the index in the array.
 * This includes visiting additional properties (if allowed by type), where the property name will
 * be the arbitrary name specified int he object.
 */
export function VisitPropertiesByType(
    value: any,
    type: Type,
    /** Returns the new value to replace the existing one with.
     *  Return the incoming `value` to leave it unchanged.
     */
    visitor: (v: any, prop: Property, pathToProp: string[]) => void,
    path: string[] = [],
    /** The property currently being visited. If unspecified, this root node will not be visited,
     * only its subtree. */
    property?: Property,
    visitedObjects = new Set<object>()
): void {
    property ??= { name: "$root", type }

    if (IsUnionType(type)) {
        const dt = Discriminate(value, type)
        if (dt !== type) {
            VisitPropertiesByType(value, dt, visitor, path, property, visitedObjects)
            return
        }
    }

    if (property) visitor(value, property, path)

    if ((IsObjectType(type) || IsIntersectionType(type)) && typeof value === "object") {
        if (visitedObjects.has(value)) return
        visitedObjects.add(value)

        GetTypeProps(type).forEach((p) => {
            if (p.name in value) {
                VisitPropertiesByType(
                    value[p.name],
                    p.type,
                    visitor,
                    [...path, p.name],
                    p,
                    visitedObjects
                )
            }
        })
        if (IsObjectType(type)) {
            const { additionalProps } = type
            if (additionalProps) {
                Object.keys(value).forEach((key) => {
                    if (!type.props.some((x) => x.name === key)) {
                        VisitPropertiesByType(
                            value[key],
                            additionalProps,
                            visitor,
                            [...path, key],
                            { name: key, type: additionalProps },
                            visitedObjects
                        )
                    }
                })
            }
        }
    } else if (IsArrayType(type) && value instanceof Array) {
        if (visitedObjects.has(value)) return
        visitedObjects.add(value)

        value.forEach((x: any, i: number) =>
            VisitPropertiesByType(
                x,
                type.array,
                visitor,
                [...path, i.toString()],
                { name: i.toString(), type: type.array },
                visitedObjects
            )
        )
    }
    return
}
