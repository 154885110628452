import { CSSProperties, createContext, useRef, useState } from "react"
import React from "react"
import { SerializedStyles } from "@emotion/react"
import { Uuid } from "../../reactor"
import { CreateDefault } from "../../reactor/Types/Defaults"
import { useEditableContext } from "./EditableContext"
import { useDirtyContext } from "./DirtyContext"
import { useHover } from "../hooks/useHover"
import { GetType } from "../../reactor/ReflectionInfo"
import { useReflectionReady } from "../../studio/reflection-client"
import { prettyCamel } from "../../reactor/Helpers"
import { GetTypeAlias, IsUnionType } from "../../reactor/Types/Type"
import { Modal } from "../modal/Modal"
import type { WidgetMap } from "../widgets/Widget"
import { SetEditableActions } from "./EditableActions"
import { ColorStyles } from "../ui"
import { useSelectionContext } from "./SelectionContext"

export const ArrayContext = createContext({
    arr: [] as any[],
})

export function EditableArray<T>({
    arr,
    direction,
    children,
    itemTypeName,
    itemStyle,
    itemClassName,
    itemCss,
    createInstance,
}: {
    arr: T[]
    direction: "row" | "column"
    children: (item: T, index: number) => React.ReactNode
    itemTypeName: string
    itemStyle?: (index: number) => CSSProperties
    itemClassName?: string | ((index: number) => string)
    itemCss?: (index: number) => SerializedStyles
    createInstance?: () => Promise<T | undefined>
}) {
    const sc = useSelectionContext()
    const { editing } = useEditableContext()
    const reflectionReady = useReflectionReady(editing)
    const type = (editing && reflectionReady && GetType(itemTypeName)) ?? undefined
    const dc = useDirtyContext()

    async function deleteAt(index: number) {
        arr.splice(index, 1)
        dc.setDirty()
    }

    const createNew =
        createInstance ??
        (async () => {
            async function findInstanceType() {
                if (type && IsUnionType(type)) {
                    const union = GetTypeAlias(type)
                    if (union) {
                        const { DocumentPicker } = await import("../../studio/Views/DocumentPicker")
                        const res = await Modal<WidgetMap>((close) => (
                            <DocumentPicker
                                collection={union}
                                current={undefined}
                                cancel={() => close(undefined)}
                                itemName={{ en: prettyCamel(union, false) + " type" }}
                                itemSelected={(row) => close(row)}
                            />
                        ))
                        if (res === undefined) return // User cancelled

                        const alias = res?._primaryKey
                        if (typeof alias === "string") {
                            return GetType(alias)
                        }
                    }
                }
                return type
            }

            const newType = await findInstanceType()

            if (newType) {
                return CreateDefault(newType)
            }
        })

    async function addAt(index: number) {
        const obj = await createNew()
        if (obj !== undefined && type) {
            arr.splice(index, 0, obj)
            sc.setSelectedObject(obj, type)
            SetEditableActions(obj, {
                deleteThis() {
                    void deleteAt(index)
                },
            })
            dc.setDirty()
        }
    }

    return (
        <ArrayContext.Provider value={{ arr }}>
            <>
                {arr.map((item, index) => (
                    <ArrayItem
                        key={index}
                        itemClassName={
                            typeof itemClassName === "string"
                                ? itemClassName
                                : itemClassName?.(index)
                        }
                        itemStyle={itemStyle?.(index)}
                        itemCss={itemCss?.(index)}
                        sectionId={(item as any)?.id?.valueOf()}
                        insertAfter={() => addAt(index + 1)}
                        insertBefore={() => addAt(index)}
                        editing={editing}
                        direction={direction}
                        isLast={index === arr.length - 1}>
                        {children(item, index)}
                    </ArrayItem>
                ))}
                {arr.length === 0 && editing && type ? (
                    <button onClick={() => addAt(0)}>
                        Add first {prettyCamel(itemTypeName, false)}
                    </button>
                ) : undefined}
            </>
        </ArrayContext.Provider>
    )
}

function ArrayItem(props: {
    editing: boolean
    children: React.ReactNode
    direction: "row" | "column"
    isLast: boolean
    insertBefore: () => void
    insertAfter: () => void
    itemStyle?: CSSProperties
    itemClassName?: string
    itemCss?: SerializedStyles
    sectionId?: string
}) {
    const ref = useRef<HTMLDivElement>(null)
    const { hover, hoverProps } = useHover()
    const [showA, setShowA] = useState(false)
    const [showB, setShowB] = useState(false)
    return (
        <div
            id={props.sectionId}
            ref={ref}
            className={props.itemClassName}
            onMouseMove={(e) => {
                e.stopPropagation()
                if (!ref.current) return
                if (e.currentTarget !== ref.current) return
                const rect = ref.current.getBoundingClientRect()

                const x = e.clientX - rect.left
                const y = e.clientY - rect.top
                let a = false
                let b = false

                if (props.direction === "row") {
                    if (x < 40) a = true
                    if (ref.current && x > rect.width - 40) b = true
                    if (y < -20 || y > rect.height + 20) {
                        a = false
                        b = false
                    }
                }
                if (props.direction === "column") {
                    if (y < 40) a = true
                    if (ref.current && y > rect.height - 40) b = true

                    if (x < -20 || x > rect.width + 20) {
                        a = false
                        b = false
                    }
                }
                setShowA(a)
                setShowB(b)
            }}
            {...hoverProps}
            style={{
                display: "flex",
                flexDirection: props.direction,
                position: "relative",
                ...props.itemStyle,
            }}
            css={props.itemCss}>
            {props.editing && (
                <AddBar
                    direction={props.direction}
                    position="before"
                    show={hover && showA}
                    onClick={props.insertBefore}
                />
            )}
            {props.children}
            {props.editing && hover && props.isLast && (
                <AddBar
                    direction={props.direction}
                    position="after"
                    show={hover && showB}
                    onClick={props.insertAfter}
                />
            )}
        </div>
    )
}

function AddBar(props: {
    direction: "row" | "column"
    position: "before" | "after"
    show: boolean
    onClick: () => void
}) {
    const { hover, hoverProps } = useHover()
    return (
        <div
            onClick={(e) => {
                e.stopPropagation()
                props.onClick()
            }}
            {...hoverProps}
            style={{
                position: "absolute",
                zIndex: 20,
                display: "flex",
                flexDirection: props.direction === "row" ? "column" : "row",
                justifyContent: "center",
                alignItems: "center",
                opacity: props.show ? 0.9 : 0,
                transition: "opacity 0.2s",

                top: props.direction === "column" && props.position === "before" ? -1.5 : undefined,
                bottom:
                    props.direction === "column" && props.position === "after" ? -1.5 : undefined,
                left: props.direction === "row" && props.position === "before" ? -1.5 : undefined,
                right: props.direction === "row" && props.position === "after" ? -1.5 : undefined,

                width: props.direction === "column" ? "100%" : 2,
                height: props.direction === "row" ? "100%" : 2,
                backgroundColor: hover ? ColorStyles.primary[500] : ColorStyles.primary[600],
                fontFamily: "Arial",
            }}>
            <div
                style={{
                    width: 24,
                    height: 24,
                    backgroundColor: hover ? ColorStyles.primary[500] : ColorStyles.primary[600],
                    borderRadius: 16,
                    fontSize: 24,
                    color: "white",
                    display: "flex",
                    flexDirection: props.direction === "row" ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    cursor: "pointer",
                }}>
                <div style={{ marginLeft: 1.25, marginTop: 0.25 }}>+</div>
            </div>
        </div>
    )
}
