import React from "react"
import { Lazy } from "../code-splitting/Lazy"
import { Outlet } from "react-router-dom"
import { DirtyContext } from "./DirtyContext"
import { CurrentLocaleProvider } from "../localization/client-side/CurrentLocaleContext"
import { DummyEditableContext, EditableContext } from "./EditableContext"
import { DummySelectionContext, SelectionContext } from "./SelectionContext"

export function EditableSiteLazy(props: { condition: boolean }) {
    return (
        <Lazy
            load={props.condition}
            target={async () => (await import("./EditableSite")).EditableSite}
            fallback={NonEditableSite}
        />
    )
}

function NonEditableSite() {
    return (
        <DirtyContext.Provider
            value={{
                version: {},
                isDirty: false,
                setDirty() {},
            }}>
            <CurrentLocaleProvider>
                <SelectionContext.Provider value={DummySelectionContext}>
                    <EditableContext.Provider value={DummyEditableContext}>
                        <Outlet />
                    </EditableContext.Provider>
                </SelectionContext.Provider>
            </CurrentLocaleProvider>
        </DirtyContext.Provider>
    )
}
