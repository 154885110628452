import { CSSProperties, useRef } from "react"
import React from "react"
import { useHover } from "../hooks/useHover"
import { useEditableContext } from "./EditableContext"
import { GetType, TypeLocator } from "../../reactor/ReflectionInfo"
import { EditableObjectActions, SetEditableActions } from "./EditableActions"
import { useSelectionContext } from "./SelectionContext"

export function EditableObject({
    obj,
    children,
    typeName,
    putType,
    style,
    actions,
    className,
}: {
    obj: any
    children: React.ReactNode
    typeName: TypeLocator
    putType?: TypeLocator
    style?: CSSProperties
    actions: EditableObjectActions
    className?: string
}) {
    const { hoverProps } = useHover()
    const { selectedObject, setSelectedObject } = useSelectionContext()
    const { editing } = useEditableContext()
    const active = selectedObject === obj
    const elementTarget = useRef(null)

    SetEditableActions(obj, actions)

    if (editing) {
        return (
            <div
                ref={elementTarget}
                {...hoverProps}
                onClick={(e) => {
                    setSelectedObject(
                        obj,
                        GetType(typeName),
                        putType ? GetType(putType) : undefined
                    )
                    e.stopPropagation()
                }}
                className={className}
                style={{
                    outline: active ? "1px solid #007bff" : "none",
                    ...style,
                }}>
                {children}
            </div>
        )
    } else {
        if (style || className) {
            return (
                <div style={style} className={className}>
                    {children}
                </div>
            )
        } else {
            return <>{children}</>
        }
    }
}
