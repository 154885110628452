import React, { CSSProperties, useRef, useState } from "react"
import { Overlay, Spinner } from "react-bootstrap"
import { useHover } from "../../packages/hooks/useHover"
import { useDebounce } from "../../reactor/Web/useDebounce"
import { Tooltip } from "react-bootstrap"
import { Placement } from "react-bootstrap/esm/Overlay"
import { Icon } from "./Icon"
import { Link } from "react-router-dom"
import { ColorStyles, OverlayShadow } from "../../packages/ui"

export type ButtonVariant = "primary" | "secondary" | "danger" | "link"

export function RButton(props: {
    style?: React.CSSProperties | ((hover: boolean) => React.CSSProperties)
    children?: React.ReactNode
    link?: string
    openLinkInNewTab?: boolean
    onClick?: (() => Promise<void>) | (() => void)
    icon?: string
    variant?: ButtonVariant
    active?: boolean
    disabled?: boolean
    popup?: (close: () => void) => React.ReactNode
    popupPlacement?: Placement
    hideChevron?: boolean
    chevron?: string
    tooltip?: string
    tooltipPlacement?: Placement
    iconSize?: number
    className?: string
    color?: string
    borderColor?: string
}) {
    const [popupVisible, setPopupVisible] = useState(false)
    const { hover, hoverProps } = useHover()
    const [busy, setBusy] = useState(false)
    const busyIndicatorVisible = useDebounce(busy, 250)

    const style = props.style instanceof Function ? props.style(hover) : props.style

    const highlight = hover && !props.disabled

    let backgroundColor: string | undefined =
        props.variant === "primary"
            ? highlight
                ? ColorStyles.primary[700]
                : ColorStyles.primary[800]
            : highlight
            ? ColorStyles.gray[100]
            : undefined

    const active = props.active || popupVisible

    let color = ColorStyles.gray[700]
    if (active) {
        color = ColorStyles.primary[700]
    }
    if (props.variant === "secondary") {
        color = ColorStyles.primary[700]
    }
    if (props.variant === "primary") {
        color = "white"
    }
    if (props.variant === "link") {
        backgroundColor = undefined
        color = hover ? ColorStyles.primary[700] : ColorStyles.primary[500]
    }
    if (props.variant === "danger" && hover) {
        backgroundColor = ColorStyles.error[500]
        color = "white" as any
    }

    if (props.color) color = props.color

    const anyContent =
        props.children !== undefined &&
        (props.children instanceof Array ? props.children.some((x) => !!x) : true)

    const { popup } = props
    const elementTarget = useRef(null)

    const hoverDelayed = useDebounce(hover, 1000)

    const iconSize = props.iconSize ?? 22

    const btn = (
        <>
            {props.tooltip && (
                <Overlay
                    target={elementTarget}
                    show={hover && hoverDelayed}
                    placement={props.tooltipPlacement ?? "top"}>
                    {(p) => (
                        <Tooltip id="overlay-example" {...p}>
                            {props.tooltip}
                        </Tooltip>
                    )}
                </Overlay>
            )}
            <div
                ref={elementTarget}
                onClick={
                    props.link && !props.onClick
                        ? undefined
                        : async (e) => {
                              e.stopPropagation()
                              if (props.disabled) return
                              if (props.onClick) {
                                  setBusy(true)
                                  await props.onClick()
                                  setBusy(false)
                              }
                              if (popup) setPopupVisible(!popupVisible)
                          }
                }
                {...hoverProps}
                className={props.className ? props.className + " hide-in-print" : "hide-in-print"}
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    opacity: props.disabled ? 0.6 : 1,
                    borderRadius: 8,
                    borderStyle: props.variant === "link" ? undefined : "solid",
                    borderWidth: props.borderColor ? 1 : 0,
                    borderColor: props.borderColor,
                    userSelect: "none",

                    padding: "0.5rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    fontSize: 16,
                    backgroundColor,
                    color,
                    cursor: props.disabled ? undefined : "pointer",
                    ...style,
                }}>
                {props.icon && (
                    <Icon
                        icon={props.icon}
                        color={color}
                        size={iconSize}
                        style={{
                            marginTop: 0,
                            marginRight:
                                props.children !== undefined || (popup && !props.hideChevron)
                                    ? 16
                                    : 0,
                            color,
                            fontSize: iconSize,
                            width: iconSize,
                            height: iconSize,
                        }}
                    />
                )}
                {props.children}
                {busyIndicatorVisible && (
                    <Spinner animation="grow" size="sm" style={{ marginLeft: 8 }} />
                )}
                {(popup || props.chevron) && !props.hideChevron && (
                    <Icon
                        color={ColorStyles.gray[700]}
                        icon={props.chevron || "chevron-down"}
                        style={{
                            marginLeft: anyContent ? 16 : 0,
                            color,
                            fontSize: 14,
                            maxWidth: 24,
                            maxHeight: 24,
                        }}
                    />
                )}
            </div>
            {popup && (
                <Overlay
                    target={elementTarget}
                    show={popupVisible}
                    placement={props.popupPlacement || "bottom-start"}>
                    {({ placement, arrowProps, show: _show, popper, ...popperProps }) => (
                        <div
                            onMouseLeave={() => setPopupVisible(false)}
                            {...popperProps}
                            style={{
                                backgroundColor: "white",
                                padding: "0.5rem",
                                boxShadow: OverlayShadow,

                                borderRadius: 3,
                                zIndex: 110,
                                ...popperProps.style,
                            }}>
                            {popup(() => setPopupVisible(false))}
                        </div>
                    )}
                </Overlay>
            )}
        </>
    )

    if (props.link && !props.disabled) {
        return (
            <Link
                to={props.link}
                target={props.openLinkInNewTab ? "_blank" : undefined}
                style={{ textDecoration: "none" }}>
                {btn}
            </Link>
        )
    } else return btn
}

export function DeleteButton({
    onClick,
    style,
}: {
    onClick: () => void
    style?: React.CSSProperties
}) {
    return (
        <RButton
            variant="danger"
            onClick={onClick}
            icon="trash"
            color={ColorStyles.gray[300]}
            style={{ borderWidth: 0, ...style }}
        />
    )
}

export function HamburgerButton({ onClick }: { onClick: () => void }) {
    return <RButton variant="primary" icon="bars" onClick={onClick}></RButton>
}

export function AddButton({
    onClick,
    text,
    style,
}: {
    onClick: () => void
    text: string
    style?: CSSProperties
}) {
    return (
        <RButton
            style={style}
            variant="secondary"
            icon="plus"
            onClick={onClick}
            className="reactor-form-small-label">
            {text}
        </RButton>
    )
}

export function ContextButton(props: { buttons: JSX.Element }) {
    return (
        <RButton
            icon="ui-dots-horizontal"
            hideChevron={true}
            style={{ borderWidth: 0, padding: 4, margin: 0 }}
            popup={() => props.buttons}
            iconSize={16}
            popupPlacement={"left-start"}
        />
    )
}

export function AddDropdown({ text, buttons }: { text: string; buttons: JSX.Element }) {
    return (
        <RButton variant="secondary" icon="plus" popup={() => buttons}>
            {text}
        </RButton>
    )
}
